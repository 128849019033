<template>
  <div id="button-with-confirm">
    <div v-on-click-outside="closeModal" class="button_with_confirm_container">
      <Transition name="slide-fade" mode="out-in">
        <div
          v-if="!confirmMode"
          class="button_with_confirm_containe__item regular_button_container"
        >
          <button class="regular_button" @click="confirmMode = true">
            {{ text }}
          </button>
        </div>
        <div
          v-else
          class="button_with_confirm_containe__item confirm_button_container"
        >
          <div class="confirm_button_container__item confirm_button__container">
            <button class="blue_button confirm_button" @click="$emit(callback)">
              Подтвердить
            </button>
          </div>
          <div class="confirm_button_container__item cancel_button_container">
            <button
              class="blue_button cancel_button"
              @click="confirmMode = false"
            >
              Отмена
            </button>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup>
import { vOnClickOutside } from "@vueuse/components";
</script>

<script>
// eslint-disable-next-line no-unused-vars
export default {
  props: {
    // OK, ERROR, WAITING
    callback: {
      type: String,
    },
    text: {
      type: String,
    },
  },
  data() {
    return {
      confirmMode: false,
    };
  },
  methods: {
    closeModal() {
      setTimeout(() => {
        this.confirmMode = false;
      }, 180);
    },
  },
  created() {
    // window.addEventListener("click", () => {
    //   this.confirmMode = false;
    // });
  },
  directives: {
    vOnClickOutside,
  },
};
</script>

<style lang="less">
@import "../../assets/styles/buttons.less";

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(0.3, 0.1, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#button-with-confirm {
  width: 100%;
  .button_with_confirm_container {
    display: flex;
  }

  .confirm_button_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 0px 0px 2px;

  }
  .regular_button_container {
      width: 100%;
    }
  .blue_button {
    height: 15px;
  }
}
</style>
